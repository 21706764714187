import '@babel/polyfill'
import $ from 'jquery'
import 'validate'
import 'bootstrap'
import Inputmask from 'inputmask'

class LoanAdvisor {
  constructor(form, rules) {
    this.rules = rules
    this.form = document.querySelector(form)
    if (!this.form) {
      return
    }
    this.steps = this.form.querySelectorAll('fieldset[id^=step]')
    this.progressBar = this.form.querySelector('ul.pagination')

    const self = this

    $('.form fieldset:first').addClass('active')
    jQuery('[data-toggle="popover"]').popover()

    for (let i = 0; i < this.steps.length; i += 1) {
      const tick = document.createElement('li')
      const span = document.createElement('span')
      tick.appendChild(span)
      if (i === 0) {
        tick.classList.add('active')
      }
      this.progressBar.appendChild(tick)
    }


    $.validator.addMethod('lessThan', (value) => {
      const i = parseFloat(value.replace('$', '').replace(/,/g, ''))
      const j = parseFloat(20000)
      return (i >= j)
    })

    $.validator.addMethod('maxAmount', (e) => {
      const t = parseFloat(e.replace('$', '').replace(/,/g, ''), 10)
      const n = parseFloat(100000)
      return t >= 1000 && t <= n
    })

    $.validator.addMethod('maxSpend', (e) => {
      const t = parseFloat(e.replace('$', '').replace(/,/g, ''), 10)
      const n = parseFloat(99999999)
      return t >= 1000 && t <= n
    })

    $.validator.addMethod('minAge', (value, element, n) => {
      const today = new Date
      const bday = new Date(value)
      return Math.floor((today - bday.getTime()) / 315576e5) >= n
    })

    $.validator.addMethod('phoneUS', (_, element) => {
      const num = element.inputmask.unmaskedvalue().replace(/\D+/g, '')
      return num.length === 10 && num.charAt(0) !== '1'
    }, 'Please specify a valid phone number')

    Inputmask({
      alias: 'currency',
      rightAlign: false,
      enforceDigitsOnBlur: false,
      removeMaskOnSubmit: true,
      min:0,
      max:100000,
    }).mask($('[name=amount_of_loan]'))

    Inputmask({
      alias: 'currency',
      rightAlign: false,
      enforceDigitsOnBlur: false,
      removeMaskOnSubmit: true,
      min:0,
      max:999999999999,
    }).mask($('[name=household_income]'))

    Inputmask({
      alias: 'currency',
      rightAlign: false,
      enforceDigitsOnBlur: false,
      removeMaskOnSubmit: true,
      min:0,
      max:999999999999,
    }).mask($('[name=mortgage_pmt_amt]'))

    Inputmask({
      mask: '99/99/9999',
      placeholder: 'mm/dd/yyyy'
    }).mask($('[name=dob]'))

    Inputmask({
      mask: '1(999)999-9999',
      removeMaskOnSubmit: true
    }).mask($('[name=phoneNumber]'))

    Inputmask({
      mask: '999-99-9999',
      numericInput: true,
      rightAlign: false
    }).mask($('[name=ssn]'))


    const validator = $(this.form).validate({
      ignore: ':hidden',
      errorContainer: '.errors',
      errorLabelContainer: '.errors',
      errorElement: 'em',
      focusCleanup: true,
      focusInvalid: false,
      errorPlacement(error, element) {
        if (element[0].type === 'checkbox') {
          element.parent('.form-check').find('.form-check-label').prepend(error.css('margin', 0))
        } else {
          element.after(error)
        }
      },
      rules: {
        amount_of_loan: {
          maxAmount: true,
          required: true
        },
        dob: {
          required: true,
          minAge: 18
        },
        email: {
          email: true,
          required: true
        },
        zipcode: {
          minlength: 5,
          required: true
        },
        household_income: {
          lessThan: true,
          required: true
        },
        ssn_agree: {
          required: true
        },
        address1: {
          required: true
        },
        phoneNumber: {
          phoneUS: true,
          required: true
        },
        ssn: {
          required: true,
          minlength: 9
        },
        type_of_loan: {
          required: true
        },
        mortgage_pmt_amt: {
          required: true
        }
      },
      messages: {
        dob: {
          required: 'Enter your DOB.',
          minAge: 'Please enter a valid date.'
        },
        email: {
          required: 'Enter your email address.'
        },
        address1: {
          required: 'Enter your full street address.'
        },
        amount_of_loan: {
          required: 'Please enter loan amount.',
          maxAmount: 'Please enter a number between $1,000 and $100,000.'
        },
        household_income: {
          lessThan: 'Your yearly income must be $20,000 or greater. Please re-enter your yearly income.',
          required: 'Please enter annual pre-tax income.'
        },
        zipcode: {
          required: 'Enter your 5 digit zipcode.'
        },
        ssn_agree: {
          required: 'Please agree to proceed.'
        },
        ssn: {
          required: 'Please enter your 10 digit social security number.'
        },
        phoneNumber: {
          required: 'Enter your phone number.'
        },
      },
      submitHandler: (form, e) => {
        e.preventDefault()
        return false
      }
    })

    function createHiddenInput(name, value) {
      if ($(`input[name=${name}]`).length > 0) {
        $(`input[name=${name}]`).val(value)
        return false
      }
      return $('<input/>').attr({
        name,
        type: 'hidden',
        value
      })
    }

    $('[name=zipcode]').on('blur', function () {
      const zip = $(this).val()
      if ($(this).valid()) {
        window.getZipCode('US', zip, (res) => {
          $('form').append([
            createHiddenInput('city', res.lookup.placeName),
            createHiddenInput('state', res.lookup.adminCode1)
          ])
        })
      }
    })


    function fieldLabel(name, label) {
      const id = `${name}-input`
      const input = $(`input[name=${name}]`).attr({
        id
      })

      $('<label/>').attr({
        for: id
      }).text(label).insertBefore(input)
    }

    fieldLabel('firstname', 'First Name')
    fieldLabel('lastname', 'Last Name')

    $('[name=phone_agree]').on('change', function () {
      if ($(this).is(':checked')) {
        $(this).removeClass('error')
        validator.resetForm()
      }
    })

    $('.backpop-enabled').on('click', () => {
      const form = $(self.form)
      // const url = form.attr('action')
      // const data = form.serialize()
      if (form.valid()) {
        jQuery('#waitingModal').modal()

        hj('formSubmitSuccessful')

        if ($('[name=credit_score_self]:checked').val() === 'excellent') {
          window.dataLayer.push({
            event: 'SubmitAppCred'
          })
        }

        $(self.form)[0].submit()


        // $.ajax({
        //   type: 'post',
        //   url,
        //   data,
        //   dataType: 'json',
        //   xhrFields: { withCredentials: true }
        // }).done((res) => {
        //   if (res.status === 'accepted') {
        //     window.dataLayer = window.dataLayer || []
        //     window.dataLayer.push({
        //       event: 'facebookCompleteReg'
        //     })
        //
        //     hj('formSubmitSuccessful')
        //
        //     window.location.replace(res.redirect)
        //   } else {
        //     window.location.replace('/credit-score.php')
        //   }
        // }).fail(() => {
        //   window.location.replace('/credit-score.php')
        // })
      } else {
        hj('formSubmitFailed')
      }
    })


    let temp
    const regex = /^(\d{3}-?\d{2}-?\d{4})$/

    $('[name=ssn]').focus(() => {
      $('[name=ssn]').val(temp)
    }).blur(() => {
      temp = $('[name=ssn]').val()
      if (regex.test($('[name=ssn]').val())) {
        $('[name=ssn]').val(`XXX-XX-${temp.slice(7, 11)}`)
      }

      $('form').append([
        createHiddenInput('ss1', temp.substring(0, 3)),
        createHiddenInput('ss2', temp.substring(4, 6)),
        createHiddenInput('ss3', temp.substring(7, 11))
      ])
    })

    function nextStep(e) {
      e.preventDefault()
      e.stopPropagation()
      const activeSlide = $(e.target).parents('.form-body.active')
      const activeTick = $(self.progressBar).find('.active')
      const idx = activeSlide.index('.form-body')
      let valid
      if (activeSlide.find('select').length > 0) {
        valid = activeSlide.find('select').valid()
      } else {
        valid = activeSlide.find('input').valid()
      }


      if (valid && idx < $('.form-body').length - 1) {
        activeSlide.fadeOut(250, () => {
          activeSlide.removeClass('active')
          activeTick.next().addClass('active')
          activeSlide.next('.form-body').addClass('active').fadeIn(250)
          $('html, body').animate({ scrollTop: $('.header-cta').prop('scrollHeight') + 54 }, 500)
        })
        if (idx === $('.form-body').length - 2) {
          $(self.progressBar).fadeOut(250)
          setTimeout(() => {
            $('.loading').fadeOut(250, () => {
              $('.ssn-view').fadeIn(250)
              $('[role=main]').addClass('ssn-step')
              $('html, body').animate({ scrollTop: $('.header').prop('scrollHeight') }, 500)
            })
          }, 4500)
        }

        window.history.pushState(null, null, null)
      }
    }

    $('select').on('change', nextStep)
    $('input[type=radio]').on('change', nextStep)
    $('.btn-primary').on('click', nextStep)

    $(document).on('keypress', 'input', (e) => {
      if (e.keyCode === 13 || e.which === 13) {
        nextStep(e)
      }
    })

    // $('button[data-name]').on('click', (e) => {
    //   if (e.target.dataset.name) {
    //     $('form').append(createHiddenInput(e.target.dataset.name, e.target.value))
    //   }
    // })

    $('[name=dob]').on('blur', (e) => {
      const date = e.target.value.split('/')
      $('form').append([
        createHiddenInput('dob_month', date[0]),
        createHiddenInput('dob_day', date[1]),
        createHiddenInput('dob_year', date[2])
      ])
    })

    // $('[name=credit_score_self]').on('click', function(){
    //   createHiddenInput('creditScore', date[2])
    // });

    $('[name=phoneNumber]').on('blur', (e) => {
      $('form').append(createHiddenInput('phone', e.target.value.replace(/^1+|[()]|-/g, '')))
    })


    window.onpopstate = function () {
      const activeSlide = $('.form-body:visible')
      const idx = activeSlide.index('.form-body')
      if (idx >= 1) {
        activeSlide.fadeOut(250, () => {
          activeSlide.removeClass('active')
          $('ul.pagination li').eq(idx).removeClass('active')
          activeSlide.prev('.form-body').addClass('active').fadeIn(250)
        })
      }
    }

    $('.back').on('click', (e) => {
      e.preventDefault()
      const activeSlide = $('.form-body:visible')
      const idx = activeSlide.index('.form-body')
      if (idx === 0) {
        window.history.back()
      }
      if (idx >= 1) {
        activeSlide.fadeOut(250, () => {
          activeSlide.removeClass('active')
          $('ul.pagination li').eq(idx).removeClass('active')
          activeSlide.prev('.form-body').addClass('active').fadeIn(250)
        })
      }
    })
  }
}

(function () {
  new LoanAdvisor('.form')
}())
